var cacheFactory = require("./work_unit_cache"),
	utils = require("../../../utils/utils");

function CalendarWorkTimeStrategy(gantt, argumentsHelper){
	this.argumentsHelper = argumentsHelper;
	this.$gantt = gantt;
	this._workingUnitsCache = cacheFactory.createCacheObject();
}

CalendarWorkTimeStrategy.prototype = {
	units: [
		"year",
		"month",
		"week",
		"day",
		"hour",
		"minute"
	],
	// cache previously calculated worktime
	_getUnitOrder: function (unit) {
		for (var i = 0, len = this.units.length; i < len; i++) {
			if (this.units[i] == unit)
				return i;
		}
	},
	_timestamp: function (settings) {

		var timestamp = null;
		if ((settings.day || settings.day === 0)) {
			timestamp = settings.day;
		} else if (settings.date) {
			// store worktime datestamp in utc so it could be recognized in different timezones (e.g. opened locally and sent to the export service in different timezone)
			timestamp = Date.UTC(settings.date.getFullYear(), settings.date.getMonth(), settings.date.getDate());
		}
		return timestamp;
	},
	_checkIfWorkingUnit: function (date, unit, order) {
		if (order === undefined) {
			order = this._getUnitOrder(unit);
		}

		// disable worktime check for custom time units
		if (order === undefined) {
			return true;
		}
		if (order) {
			//check if bigger time unit is a work time (hour < day < month...)
			//i.e. don't check particular hour if the whole day is marked as not working
			if (!this._isWorkTime(date, this.units[order - 1], order - 1))
				return false;
		}
		if (!this["_is_work_" + unit])
			return true;
		return this["_is_work_" + unit](date);
	},
	//checkings for particular time units
	//methods for month-year-week can be defined, otherwise always return 'true'
	_is_work_day: function (date) {
		var val = this._getWorkHours(date);

		if (val instanceof Array) {
			return val.length > 0;
		}
		return false;
	},
	_is_work_hour: function (date) {
		var hours = this._getWorkHours(date); // [7,12] or []
		var hour = date.getHours();
		for (var i = 0; i < hours.length; i += 2) {
			if (hours[i + 1] === undefined) {
				return hours[i] == hour;
			} else {
				if (hour >= hours[i] && hour < hours[i + 1])
					return true;
			}
		}
		return false;
	},
	_internDatesPull: {},
	_nextDate: function (start, unit, step) {
		var dateHelper = this.$gantt.date;
		return dateHelper.add(start, step, unit);

		/*var start_value = +start,
			key = unit + "_" + step;
		var interned = this._internDatesPull[key];
		if(!interned){
			interned = this._internDatesPull[key] = {};
		}
		var calculated;
		if(!interned[start_value]){
			interned[start_value] = calculated = dateHelper.add(start, step, unit);
			//interned[start_value] = dateHelper.add(start, step, unit);
		}
		return calculated || interned[start_value];*/
	},
	_getWorkUnitsBetweenGeneric: function (from, to, unit, step) {
		var dateHelper = this.$gantt.date;
		var start = new Date(from),
			end = new Date(to);
		step = step || 1;
		var units = 0;


		var next = null;
		var stepStart,
			stepEnd;

		// calculating decimal durations, i.e. 2016-09-20 00:05:00 - 2016-09-20 01:00:00 ~ 0.95 instead of 1
		// and also  2016-09-20 00:00:00 - 2016-09-20 00:05:00 ~ 0.05 instead of 1
		// durations must be rounded later
		var checkFirst = false;
		stepStart = dateHelper[unit + "_start"](new Date(start));
		if (stepStart.valueOf() != start.valueOf()) {
			checkFirst = true;
		}
		var checkLast = false;
		stepEnd = dateHelper[unit + "_start"](new Date(to));
		if (stepEnd.valueOf() != to.valueOf()) {
			checkLast = true;
		}

		var isLastStep = false;
		while (start.valueOf() < end.valueOf()) {
			next = this._nextDate(start, unit, step);
			isLastStep = (next.valueOf() > end.valueOf());

			if (this._isWorkTime(start, unit)) {
				if (checkFirst || (checkLast && isLastStep)) {
					stepStart = dateHelper[unit + "_start"](new Date(start));
					stepEnd = dateHelper.add(stepStart, step, unit);
				}

				if (checkFirst) {
					checkFirst = false;
					next = this._nextDate(stepStart, unit, step);
					units += ((stepEnd.valueOf() - start.valueOf()) / (stepEnd.valueOf() - stepStart.valueOf()));
				} else if (checkLast && isLastStep) {
					checkLast = false;
					units += ((end.valueOf() - start.valueOf()) / (stepEnd.valueOf() - stepStart.valueOf()));

				} else {
					units++;
				}
			}
			start = next;
		}
		return units;
	},

	_getMinutesPerDay: function (date) {
		// current api doesn't allow setting working minutes, so use hardcoded 60 minutes per hour
		return this._getHoursPerDay(date) * 60;
	},
	_getHoursPerDay: function (date) {
		var hours = this._getWorkHours(date);
		var res = 0;
		for (var i = 0; i < hours.length; i += 2) {
			res += ((hours[i + 1] - hours[i]) || 0);
		}
		return res;
	},
	_getWorkUnitsForRange: function (from, to, unit, step) {
		var total = 0;
		var start = new Date(from),
			end = new Date(to);

		var getUnitsPerDay;
		if (unit == "minute") {
			getUnitsPerDay = utils.bind(this._getMinutesPerDay, this);
		} else {
			getUnitsPerDay = utils.bind(this._getHoursPerDay, this);
		}

		while (start.valueOf() < end.valueOf()) {
			if (this._isWorkTime(start, "day")) {
				total += getUnitsPerDay(start);
			}
			start = this._nextDate(start, "day", 1);
		}

		return total / step;
	},

	// optimized method for calculating work units duration of large time spans
	// implemented for hours and minutes units, bigger time units don't benefit from the optimization so much
	_getWorkUnitsBetweenQuick: function (from, to, unit, step) {
		var start = new Date(from),
			end = new Date(to);
		step = step || 1;

		var firstDayStart = new Date(start);
		var firstDayEnd = this.$gantt.date.add(this.$gantt.date.day_start(new Date(start)), 1, "day");

		if (end.valueOf() <= firstDayEnd.valueOf()) {
			return this._getWorkUnitsBetweenGeneric(from, to, unit, step);
		} else {

			var lastDayStart = this.$gantt.date.day_start(new Date(end));
			var lastDayEnd = end;

			var startPart = this._getWorkUnitsBetweenGeneric(firstDayStart, firstDayEnd, unit, step);
			var endPart = this._getWorkUnitsBetweenGeneric(lastDayStart, lastDayEnd, unit, step);

			var rangePart = this._getWorkUnitsForRange(firstDayEnd, lastDayStart, unit, step);
			var total = startPart + rangePart + endPart;

			return total;
		}
	},

	_getCalendar: function () {
		return this.worktime;
	},
	_setCalendar: function (settings) {
		this.worktime = settings;
	},

	_tryChangeCalendarSettings: function (payload) {
		var backup = JSON.stringify(this._getCalendar());
		payload();
		if (this._isEmptyCalendar(this._getCalendar())) {
			this.$gantt.assert(false, "Invalid calendar settings, no worktime available");
			this._setCalendar(JSON.parse(backup));
			this._workingUnitsCache.clear();
			return false;
		}
		return true;

	},

	_isEmptyCalendar: function (settings) {
		var result = false,
			datesArray = [],
			isFullWeekSet = true;
		for (var i in settings.dates) {
			result |= !!settings.dates[i];
			datesArray.push(i);
		}

		var checkFullArray = [];
		for (var i = 0; i < datesArray.length; i++) {
			if (datesArray[i] < 10) {
				checkFullArray.push(datesArray[i]);
			}
		}
		checkFullArray.sort();

		for (var i = 0; i < 7; i++) {
			if (checkFullArray[i] != i)
				isFullWeekSet = false;
		}
		if (isFullWeekSet)
			return !result;
		return !(result || !!settings.hours); // can still return false if separated dates are set to true
	},

	getWorkHours: function () {
		var config = this.argumentsHelper.getWorkHoursArguments.apply(this.argumentsHelper, arguments);
		return this._getWorkHours(config.date);
	},
	_getWorkHours: function (date) {
		var t = this._timestamp({date: date});
		var hours = true;
		var calendar = this._getCalendar();
		if (calendar.dates[t] !== undefined) {
			hours = calendar.dates[t];//custom day
		} else if (calendar.dates[date.getDay()] !== undefined) {
			hours = calendar.dates[date.getDay()];//week day
		}
		if (hours === true) {
			return calendar.hours;
		} else if (hours) {
			return hours;
		}
		return [];
	},

	setWorkTime: function (settings) {
		return this._tryChangeCalendarSettings(utils.bind(function () {
			var hours = settings.hours !== undefined ? settings.hours : true;
			var timestamp = this._timestamp(settings);
			if (timestamp !== null) {
				this._getCalendar().dates[timestamp] = hours;
			} else {
				this._getCalendar().hours = hours;
			}
			this._workingUnitsCache.clear();
		}, this));
	},

	unsetWorkTime: function (settings) {
		return this._tryChangeCalendarSettings(utils.bind(function () {
			if (!settings) {
				this.reset_calendar();
			} else {

				var timestamp = this._timestamp(settings);

				if (timestamp !== null) {
					delete this._getCalendar().dates[timestamp];
				}
			}
			// Clear work units cache
			this._workingUnitsCache.clear();
		}, this));
	},

	_isWorkTime: function (date, unit, order) {
		// Check if this item has in the cache

		// use string keys
		var dateKey = String(date.valueOf());
		var is_work_unit = this._workingUnitsCache.getItem(unit, dateKey);

		if (is_work_unit == -1) {
			// calculate if not cached
			is_work_unit = this._checkIfWorkingUnit(date, unit, order);
			this._workingUnitsCache.setItem(unit, dateKey, is_work_unit);
		}

		return is_work_unit;
	},

	isWorkTime: function () {
		var config =  this.argumentsHelper.isWorkTimeArguments.apply( this.argumentsHelper, arguments);
		return this._isWorkTime(config.date, config.unit);
	},

	calculateDuration: function () {
		var config =  this.argumentsHelper.getDurationArguments.apply( this.argumentsHelper, arguments);

		if (!config.unit) {
			return false;
		}
		return this._calculateDuration(config.start_date, config.end_date, config.unit, config.step);
	},

	_calculateDuration: function (from, to, unit, step) {
		var res = 0;
		if (unit == "hour" || unit == "minute") {
			res = this._getWorkUnitsBetweenQuick(from, to, unit, step);
		} else {
			res = this._getWorkUnitsBetweenGeneric(from, to, unit, step);
		}

		// getWorkUnits.. returns decimal durations
		return Math.round(res);
	},
	hasDuration: function () {
		var config =  this.argumentsHelper.getDurationArguments.apply( this.argumentsHelper, arguments);

		var from = config.start_date,
			to = config.end_date,
			unit = config.unit,
			step = config.step;

		if (!unit) {
			return false;
		}
		var start = new Date(from),
			end = new Date(to);
		step = step || 1;

		while (start.valueOf() < end.valueOf()) {
			if (this._isWorkTime(start, unit))
				return true;
			start = this._nextDate(start, unit, step);
		}
		return false;
	},

	calculateEndDate: function () {
		var config =  this.argumentsHelper.calculateEndDateArguments.apply( this.argumentsHelper, arguments);

		var from = config.start_date,
			duration = config.duration,
			unit = config.unit,
			step = config.step;

		if (!unit)
			return false;

		var mult = (config.duration >= 0) ? 1 : -1;
		duration = Math.abs(duration * 1);
		return this._calculateEndDate(from, duration, unit, step * mult);
	},

	_calculateEndDate: function (from, duration, unit, step) {
		if (!unit)
			return false;

		if (step == 1 && unit == "minute") {
			return this._calculateMinuteEndDate(from, duration, step);
		} else if (step == 1 && unit == "hour") {
			return this._calculateHourEndDate(from, duration, step);
		} else {
			var interval = this._addInterval(from, duration, unit, step, null);
			return interval.end;
		}
	},

	_addInterval: function (start, duration, unit, step, stopAction) {
		var added = 0;
		var current = start;
		while (added < duration && !(stopAction && stopAction(current))) {
			var next = this._nextDate(current, unit, step);
			if (this._isWorkTime(step > 0 ? new Date(next.valueOf() - 1) : new Date(next.valueOf() + 1), unit)) {
				added++;
			}
			current = next;
		}
		return {
			end: current,
			satrt: start,
			added: added
		};
	},

	_calculateHourEndDate: function (from, duration,  step) {
		var start = new Date(from),
		added = 0;
		step = step || 1;
		duration = Math.abs(duration * 1);

		var interval = this._addInterval(start, duration, "hour", step, function (date) {
			// iterate until hour end
			if (!(date.getHours() || date.getMinutes() || date.getSeconds() || date.getMilliseconds())) {
				return true;
			}
			return false;
		});

		added = interval.added;
		start = interval.end;

		var durationLeft = duration - added;

		if (durationLeft && durationLeft > 24) {
			var current = start;
			while (added < duration) {
				var next = this._nextDate(current, "day", step);
				// reset to day start in case DST switch happens in the process
				next.setHours(0);
				next.setMinutes(0);
				next.setSeconds(0);
				if (this._isWorkTime(step > 0 ? new Date(next.valueOf() - 1) : new Date(next.valueOf() + 1), "day")) {
					var hours = this._getHoursPerDay(current);
					if (added + hours >= duration) {
						break;
					} else {
						added += hours;
					}
				}
				current = next;
			}
			start = current;
		}

		if (added < duration) {
			var durationLeft = duration - added;
			interval = this._addInterval(start, durationLeft, "hour", step, null);
			start = interval.end;
		}

		return start;
	},

	_calculateMinuteEndDate: function (from, duration, step) {

		var start = new Date(from),
			added = 0;
		step = step || 1;
		duration = Math.abs(duration * 1);

		var interval = this._addInterval(start, duration, "minute", step, function (date) {
			// iterate until hour end
			if (!(date.getMinutes() || date.getSeconds() || date.getMilliseconds())) {
				return true;
			}
			return false;
		});

		added = interval.added;
		start = interval.end;

		if (added < duration) {
			var left = duration - added;
			var hours = Math.floor(left / 60);
			if (hours) {
				start = this._calculateEndDate(start, hours, "hour", step > 0 ? 1 : -1);
				added += hours * 60;
			}
		}

		if (added < duration) {
			var durationLeft = duration - added;
			interval = this._addInterval(start, durationLeft, "minute", step, null);
			start = interval.end;
		}

		return start;
	},

	getClosestWorkTime: function () {
		var settings =  this.argumentsHelper.getClosestWorkTimeArguments.apply( this.argumentsHelper, arguments);
		return this._getClosestWorkTime(settings.date, settings.unit, settings.dir);
	},

	_getClosestWorkTime: function (inputDate, unit, direction) {
		var result = new Date(inputDate);

		if (this._isWorkTime(result, unit)) {
			return result;
		}

		result = this.$gantt.date[unit + '_start'](result);

		if (direction == 'any' || !direction) {
			var closestFuture = this._getClosestWorkTimeFuture(result, unit);
			var closestPast = this._getClosestWorkTimePast(result, unit);
			if (Math.abs(closestFuture - inputDate) <= Math.abs(inputDate - closestPast)) {
				result = closestFuture;
			} else {
				result = closestPast;
			}
		} else if (direction == "past") {
			result = this._getClosestWorkTimePast(result, unit);
		} else {
			result = this._getClosestWorkTimeFuture(result, unit);
		}
		return result;
	},

	_getClosestWorkTimeFuture: function (date, unit) {
		return this._getClosestWorkTimeGeneric(date, unit, 1);
	},

	_getClosestWorkTimePast: function (date, unit) {
		var result = this._getClosestWorkTimeGeneric(date, unit, -1);
		// should return the end of the closest work interval
		return this.$gantt.date.add(result, 1, unit);
	},

	_getClosestWorkTimeGeneric: function (date, unit, increment) {
		var unitOrder = this._getUnitOrder(unit),
			biggerTimeUnit = this.units[unitOrder - 1];

		var result = date;

		// be extra sure we won't fall into infinite loop, 3k seems big enough
		var maximumLoop = 3000,
			count = 0;

		while (!this._isWorkTime(result, unit)) {
			if (biggerTimeUnit && !this._isWorkTime(result, biggerTimeUnit)) {
				// if we look for closest work hour and detect a week-end - first find the closest work day,
				// and continue iterations after that
				if (increment > 0) {
					result = this._getClosestWorkTimeFuture(result, biggerTimeUnit);
				} else {
					result = this._getClosestWorkTimePast(result, biggerTimeUnit);
				}

				if (this._isWorkTime(result, unit)) {
					break;
				}
			}

			count++;
			if (count > maximumLoop) {
				this.$gantt.assert(false, "Invalid working time check");
				return false;
			}

			var tzOffset = result.getTimezoneOffset();
			result = this.$gantt.date.add(result, increment, unit);

			result = this.$gantt._correct_dst_change(result, tzOffset, increment, unit);
			if (this.$gantt.date[unit + '_start']) {
				result = this.$gantt.date[unit + '_start'](result);
			}
		}
		return result;
	}
};

module.exports = CalendarWorkTimeStrategy;