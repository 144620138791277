import * as eventable from "../../utils/eventable";
import * as helpers from "../../utils/helpers";
import * as utils from "../../utils/utils";
import DataProcessorEvents from "./data_processor_events";
import extendGantt from "./extend_gantt";

export interface DataProcessor { // tslint:disable-line
	$gantt: any;
	detachAllEvents: any;
	attachEvent: any;
	callEvent: any;

	serverProcessor: string;
	action_param: string;
	object: any;
	updatedRows: any[];
	autoUpdate: boolean;
	updateMode: string;
	mandatoryFields: any[];
	messages: any[];
	styles: object;
	dnd: any;
}

export function createDataProcessor(config: any) {
	let router;
	let tMode;
	if (config instanceof Function) {
		router = config;
	} else if (config.hasOwnProperty("router")) {
		router = config.router;
	} else if (config.hasOwnProperty("link") && config.hasOwnProperty("task")) {
		router = config;
	}
	if (router) {
		tMode = "CUSTOM";
	} else {
		tMode = config.mode || "REST-JSON";
	}
	const gantt = this; // tslint:disable-line
	const dp = new DataProcessor(config.url);
	dp.init(gantt);
	dp.setTransactionMode({
		mode: tMode,
		router
	}, config.batchUpdate);
	return dp;
}

export class DataProcessor {
	public modes: object;
	public serverProcessor: string;
	public action_param: string; // tslint:disable-line
	public object: any;
	public updatedRows: any[];
	public autoUpdate: boolean;
	public updateMode: string;
	public mandatoryFields: any[];
	public messages: any[];
	public styles: object;
	public dnd: any;

	protected _tMode: string;
	protected _headers: any;
	protected _payload: any;
	protected _postDelim: string;
	protected _waitMode: number;
	protected _in_progress: object; // tslint:disable-line
	protected _invalid: object;
	protected _tSend: boolean;
	protected _endnm: boolean;
	protected _serializeAsJson: boolean;
	protected _router: any;
	protected _utf: boolean;
	protected obj: any;
	protected _columns: any;
	protected _changed: boolean;
	protected _methods: any[];
	protected _user: any;
	protected _uActions: object;
	protected _needUpdate: boolean;
	protected _ganttMode: string;

	protected _silent_mode: any; // tslint:disable-line
	protected _updateBusy: any;
	protected _serverProcessor: any;
	protected _initialized: boolean;

	constructor(serverProcessorURL?) {
		this.serverProcessor = serverProcessorURL;
		this.action_param = "!nativeeditor_status";

		this.object = null;
		this.updatedRows = []; // ids of updated rows

		this.autoUpdate = true;
		this.updateMode = "cell";
		this._headers = null;
		this._payload = null;
		this._postDelim = "_";

		this._waitMode = 0;
		this._in_progress = {}; // ?
		this._invalid = {};
		this.mandatoryFields = [];
		this.messages = [];

		this.styles = {
			updated: "font-weight:bold;",
			inserted: "font-weight:bold;",
			deleted: "text-decoration : line-through;",
			invalid: "background-color:FFE0E0;",
			invalid_cell: "border-bottom:2px solid red;",
			error: "color:red;",
			clear: "font-weight:normal;text-decoration:none;"
		};
		this.enableUTFencoding(true);
		eventable(this);
	}

	setTransactionMode(mode:any, total?:any) {
		if (typeof mode === "object") {
			this._tMode = mode.mode || this._tMode;

			if (utils.defined(mode.headers)) {
				this._headers = mode.headers;
			}

			if (utils.defined(mode.payload)) {
				this._payload = mode.payload;
			}

		} else {
			this._tMode = mode;
			this._tSend = total;
		}

		if (this._tMode === "REST") {
			this._tSend = false;
			this._endnm = true;
		}

		if (this._tMode === "JSON" || this._tMode === "REST-JSON") {
			this._tSend = false;
			this._endnm = true;
			this._serializeAsJson = true;
			this._headers = this._headers || {};
			this._headers["Content-type"] = "application/json";
		}

		if (this._tMode === "CUSTOM") {
			this._tSend = false;
			this._endnm = true;
			this._router = mode.router;
		}
	}

	escape(data:any) {
		if (this._utf) {
			return encodeURIComponent(data);
		} else {
			return escape(data);
		}
	}

	/**
	 * @desc: allows to set escaping mode
	 * @param: true - utf based escaping, simple - use current page encoding
	 * @type: public
	 */
	enableUTFencoding(mode:boolean) {
		this._utf = !!mode;
	}


	/**
	 * @desc: allows to define, which column may trigger update
	 * @param: val - array or list of true/false values
	 * @type: public
	 */
	setDataColumns(val:string|any) {
		this._columns = (typeof val === "string") ? val.split(",") : val;
	}

	/**
	 * @desc: get state of updating
	 * @returns:   true - all in sync with server, false - some items not updated yet.
	 * @type: public
	 */
	getSyncState() {
		return !this.updatedRows.length;
	}

	/**
	 * @desc: enable/disable named field for data syncing, will use column ids for grid
	 * @param:   mode - true/false
	 * @type: public
	 */
	enableDataNames(mode: boolean) {
		this._endnm = !!mode;
	}

	/**
	 * @desc: enable/disable mode , when only changed fields and row id send to the server side, instead of all fields in default mode
	 * @param:   mode - true/false
	 * @type: public
	 */
	enablePartialDataSend(mode: boolean) {
		this._changed = !!mode;
	}

	/**
	 * @desc: set if rows should be send to server automaticaly
	 * @param: mode - "row" - based on row selection changed, "cell" - based on cell editing finished, "off" - manual data sending
	 * @type: public
	 */
	setUpdateMode(mode: string, dnd: any) {
		this.autoUpdate = (mode === "cell");
		this.updateMode = mode;
		this.dnd = dnd;
	}

	ignore(code: any, master: any) {
		this._silent_mode = true;
		code.call(master || window);
		this._silent_mode = false;
	}

	/**
	 * @desc: mark row as updated/normal. check mandatory fields,initiate autoupdate (if turned on)
	 * @param: rowId - id of row to set update-status for
	 * @param: state - true for "updated", false for "not updated"
	 * @param: mode - update mode name
	 * @type: public
	 */
	setUpdated(rowId:number|string, state: boolean, mode?: string) {
		if (this._silent_mode) {
			return;
		}

		const ind = this.findRow(rowId);

		mode = mode || "updated";
		const existing = this.$gantt.getUserData(rowId, this.action_param);
		if (existing && mode === "updated") {
			mode = existing;
		}
		if (state) {
			this.set_invalid(rowId, false); // clear previous error flag
			this.updatedRows[ind] = rowId;
			this.$gantt.setUserData(rowId, this.action_param, mode);
			if (this._in_progress[rowId]) {
				this._in_progress[rowId] = "wait";
			}
		} else {
			if (!this.is_invalid(rowId)) {
				this.updatedRows.splice(ind, 1);
				this.$gantt.setUserData(rowId, this.action_param, "");
			}
		}

		this.markRow(rowId, state, mode);
		if (state && this.autoUpdate) {
			this.sendData(rowId);
		}
	}

	markRow(id: number | string, state: boolean, mode: string) {
		let str = "";
		const invalid = this.is_invalid(id);
		if (invalid) {
			str = this.styles[invalid];
			state = true;
		}
		if (this.callEvent("onRowMark", [id, state, mode, invalid])) {
			// default logic
			str = this.styles[state ? mode : "clear"] + str;

			this.$gantt[this._methods[0]](id, str);

			if (invalid && invalid.details) {
				str += this.styles[invalid + "_cell"];
				for (let i = 0; i < invalid.details.length; i++) {
					if (invalid.details[i]) {
						this.$gantt[this._methods[1]](id, i, str);
					}
				}
			}
		}
	}

	getActionByState(state: string):string {
		if (state === "inserted") {
			return "create";
		}

		if (state === "updated") {
			return "update";
		}

		if (state === "deleted") {
			return "delete";
		}

		// reorder
		return "update";
	}

	getState(id: number | string) {
		return this.$gantt.getUserData(id, this.action_param);
	}

	is_invalid(id: number | string) {
		return this._invalid[id];
	}

	set_invalid(id: number | string, mode: any, details?) {
		if (details) {
			mode = {
				value: mode,
				details,
				toString: function() { // tslint:disable-line
					return this.value.toString();
				}
			};
		}
		this._invalid[id] = mode;
	}

	/**
	 * @desc: check mandatory fields and varify values of cells, initiate update (if specified)
	 * @param: rowId - id of row to set update-status for
	 * @type: public
	 */
	// tslint:disable-next-line
	checkBeforeUpdate(rowId: number | string) { // ???
		return true;
	}

	/**
	 * @desc: send row(s) values to server
	 * @param: rowId - id of row which data to send. If not specified, then all "updated" rows will be send
	 * @type: public
	 */
	sendData(rowId?: any) {
		if (this._waitMode && (this.$gantt.mytype === "tree" || this.$gantt._h2)) {
			return;
		}
		if (this.$gantt.editStop) {
			this.$gantt.editStop();
		}


		if (typeof rowId === "undefined" || this._tSend) {
			return this.sendAllData();
		}
		if (this._in_progress[rowId]) {
			return false;
		}

		this.messages = [];
		if (!this.checkBeforeUpdate(rowId) && this.callEvent("onValidationError", [rowId, this.messages])) {
			return false; // ??? unreachable code, drop it?
		}
		this._beforeSendData(this._getRowData(rowId), rowId);
	}

	_beforeSendData(data: any, rowId: any) {
		if (!this.callEvent("onBeforeUpdate", [rowId, this.getState(rowId), data])) {
			return false;
		}
		this._sendData(data, rowId);
	}

	serialize(data: any, id: any) {
		if (this._serializeAsJson) {
			return  this._serializeAsJSON(data);
		}

		if (typeof data === "string") {
			return data;
		}
		if (typeof id !== "undefined") {
			return this.serialize_one(data, "");
		} else {
			const stack = [];
			const keys = [];
			for (const key in data) {
				if (data.hasOwnProperty(key)) {
					stack.push(this.serialize_one(data[key], key + this._postDelim));
					keys.push(key);
				}
			}
			stack.push("ids=" + this.escape(keys.join(",")));
			if (this.$gantt.security_key) {
				stack.push("dhx_security=" + this.$gantt.security_key);
			}
			return stack.join("&");
		}
	}

	_serializeAsJSON(data: any) {
		if (typeof data === "string") {
			return data;
		}

		const copy = utils.copy(data);
		if (this._tMode === "REST-JSON") {
			delete copy.id;
			delete copy[this.action_param];
		}

		return JSON.stringify(copy);
	}

	serialize_one(data: any, pref: string) {
		if (typeof data === "string") {
			return data;
		}
		const stack = [];
		let serialized = "";
		for (const key in data)
			if (data.hasOwnProperty(key)) {
				if ((key === "id" ||
					key == this.action_param) && // tslint:disable-line
					this._tMode === "REST") {
					continue;
				}
				if (typeof data[key] === "string" || typeof data[key] === "number") {
					serialized = data[key];
				} else {
					serialized = JSON.stringify(data[key]);
				}
				stack.push(this.escape((pref || "") + key) + "=" + this.escape(serialized));
			}
		return stack.join("&");
	}

	_applyPayload(url: string) {
		const ajax = this.$gantt.ajax;
		if (this._payload) {
			for (const key in this._payload) {
				url = url + ajax.urlSeparator(url) + this.escape(key) + "=" + this.escape(this._payload[key]);
			}
		}
		return url;
	}

	_sendData(dataToSend: any, rowId?: any) {
		if (!dataToSend) {
			return; // nothing to send
		}
		if (!this.callEvent("onBeforeDataSending", rowId ? [rowId, this.getState(rowId), dataToSend] : [null, null, dataToSend])) {
			return false;
		}

		if (rowId) {
			this._in_progress[rowId] = (new Date()).valueOf();
		}

		const ajax = this.$gantt.ajax;

		if (this._tMode === "CUSTOM") {
			const taskState = this.getState(rowId);
			const taskAction = this.getActionByState(taskState);
			const ganttMode = this.getGanttMode();
			const _onResolvedCreateUpdate = (tag) => {
				let action = taskState || "updated";
				let sid = rowId;
				let tid = rowId;

				if (tag) {
					action = tag.action || taskState;
					sid = tag.sid || sid;
					tid = tag.id || tag.tid || tid;
				}
				this.afterUpdateCallback(sid, tid, action, tag);
			};

			let actionPromise;
			if (this._router instanceof Function) {
				actionPromise = this._router(ganttMode, taskAction, dataToSend, rowId);
			} else if (this._router[ganttMode] instanceof Function) {
				actionPromise = this._router[ganttMode](taskAction, dataToSend, rowId);
			} else {
				switch (taskState) {
					case "inserted":
						actionPromise = this._router[ganttMode].create(dataToSend);
						break;
					case "deleted":
						actionPromise = this._router[ganttMode].delete(rowId);
						break;
					default:
						actionPromise = this._router[ganttMode].update(dataToSend, rowId);
						break;
				}
			}

			if(actionPromise){
				// neither promise nor {tid: newId} response object
				if(!actionPromise.then &&
					(actionPromise.id === undefined && actionPromise.tid === undefined)){
					throw new Error("Incorrect router return value. A Promise or a response object is expected");
				}

				if(actionPromise.then){
					actionPromise.then(_onResolvedCreateUpdate);
				}else{
					// custom method may return a response object in case of sync action
					_onResolvedCreateUpdate(actionPromise);
				}
			}else{
				_onResolvedCreateUpdate(null);
			}
			return;
		}

		let queryParams: any;
		queryParams = {
			callback: (xml) => {
				const ids = [];

				if (rowId) {
					ids.push(rowId);
				} else if (dataToSend) {
					for (const key in dataToSend) {
						ids.push(key);
					}
				}

				return this.afterUpdate(this, xml, ids);
			},
			headers: this._headers
		};

		const urlParams = this.serverProcessor + (this._user ? (ajax.urlSeparator(this.serverProcessor) + ["dhx_user=" + this._user, "dhx_version=" + this.$gantt.getUserData(0, "version")].join("&")) : "");
		let url: any = this._applyPayload(urlParams);
		let data;

		switch (this._tMode) {
			case "GET":
				queryParams.url = url + ajax.urlSeparator(url) + this.serialize(dataToSend, rowId);
				queryParams.method = "GET";
				break;
			case "POST":
				queryParams.url = url;
				queryParams.method = "POST";
				queryParams.data = this.serialize(dataToSend, rowId);
				break;
			case "JSON":
				data = {};
				for (const key in dataToSend) {
					if (key === this.action_param || key === "id" || key === "gr_id") {
						continue;
					}
					data[key] = dataToSend[key];
				}

				queryParams.url = url;
				queryParams.method = "POST";
				queryParams.data = JSON.stringify({
					id: rowId,
					action: dataToSend[this.action_param],
					data
				});
				break;
			case "REST":
			case "REST-JSON":
				url = urlParams.replace(/(&|\?)editing=true/, "");
				data = "";

				switch (this.getState(rowId)) {
					case "inserted":
						queryParams.method = "POST";
						queryParams.data = this.serialize(dataToSend, rowId);
						break;
					case "deleted":
						queryParams.method = "DELETE";
						url = url + (url.slice(-1) === "/" ? "" : "/") + rowId;
						break;
					default:
						queryParams.method = "PUT";
						queryParams.data = this.serialize(dataToSend, rowId);
						url = url + (url.slice(-1) === "/" ? "" : "/") + rowId;
						break;
				}
				queryParams.url = this._applyPayload(url);
				break;
		}

		this._waitMode++;
		return ajax.query(queryParams);
	}

	_forEachUpdatedRow(code: any) {
		const updatedRows = this.updatedRows.slice();
		for (let i = 0; i < updatedRows.length; i++) {
			const rowId = updatedRows[i];
			if (this.$gantt.getUserData(rowId, this.action_param)) {
				code.call(this, rowId);
			}
		}
	}

	sendAllData() {
		if (!this.updatedRows.length) {
			return;
		}

		this.messages = [];
		let valid: any = true;

		this._forEachUpdatedRow(function(rowId) {
			valid = valid && this.checkBeforeUpdate(rowId); // ??? checkBeforeUpdate() always is true
		});

		if (!valid && !this.callEvent("onValidationError", ["", this.messages])) {
			return false;
		}

		if (this._tSend) {
			this._sendData(this._getAllData());
		} else {
			let stop = false;

			// this.updatedRows can be spliced from onBeforeUpdate via dp.setUpdated false
			// use an iterator instead of for(var i = 0; i < this.updatedRows; i++) then
			this._forEachUpdatedRow(function(rowId) {
				if (stop) {
					return;
				}

				if (!this._in_progress[rowId]) {
					if (this.is_invalid(rowId)) {
						return;
					}
					this._beforeSendData(this._getRowData(rowId), rowId);
					if (this._waitMode && (this.$gantt.mytype === "tree" || this.$gantt._h2)) {
						stop = true; // block send all for tree
					}
				}
			});
		}
	}

	_getAllData() {
		const out = {};
		let hasOne = false;

		this._forEachUpdatedRow(function(id) {
			if (this._in_progress[id] || this.is_invalid(id)){
				return;
			}
			const row = this._getRowData(id);
			if (!this.callEvent("onBeforeUpdate", [id, this.getState(id), row])) {
				return;
			}
			out[id] = row;
			hasOne = true;
			this._in_progress[id] = (new Date()).valueOf();
		});

		return hasOne ? out : null;
	}


	/**
	 * @desc: specify column which value should be verified before sending to server
	 * @param: ind - column index (0 based)
	 * @param: verifFunction - function(object) which should verify cell value (if not specified, then value will be compared to empty string). Two arguments will be passed into it: value and column name
	 * @type: public
	 */
	setVerificator(ind: number, verifFunction: any) {
		this.mandatoryFields[ind] = verifFunction || (function(value) { return (value !== ""); });
	}

	/**
	 * @desc: remove column from list of those which should be verified
	 * @param: ind - column Index (0 based)
	 * @type: public
	 */
	clearVerificator(ind: number) {
		this.mandatoryFields[ind] = false;
	}

	findRow(pattern: any) {
		let i = 0;
		for (i = 0; i < this.updatedRows.length; i++) {
			if (pattern == this.updatedRows[i]) { // tslint:disable-line
				break;
			}
		}
		return i;
	}

	/**
	 * @desc: define custom actions
	 * @param: name - name of action, same as value of action attribute
	 * @param: handler - custom function, which receives a XMl response content for action
	 * @type: private
	 */
	defineAction(name: string, handler: any) {
		if (!this._uActions) {
			this._uActions = {};
		}
		this._uActions[name] = handler;
	}

	/**
	 * @desc: used in combination with setOnBeforeUpdateHandler to create custom client-server transport system
	 * @param: sid - id of item before update
	 * @param: tid - id of item after up0ate
	 * @param: action - action name
	 * @type: public
	 * @topic: 0
	 */
	afterUpdateCallback(sid: number | string, tid: number | string, action: string, btag: any) {
		if(!this.$gantt){
			// destructor has been called before the callback
			return;
		}

		const marker = sid;
		const correct = (action !== "error" && action !== "invalid");
		if (!correct) {
			this.set_invalid(sid, action);
		}
		if ((this._uActions) && (this._uActions[action]) && (!this._uActions[action](btag))) {
			return (delete this._in_progress[marker]);
		}

		if (this._in_progress[marker] !== "wait") {
			this.setUpdated(sid, false);
		}

		const originalSid = sid;

		switch (action) {
			case "inserted":
			case "insert":
				if (tid != sid) { // tslint:disable-line
					this.setUpdated(sid, false);
					this.$gantt[this._methods[2]](sid, tid);
					sid = tid;
				}
				break;
			case "delete":
			case "deleted":
				this.$gantt.setUserData(sid, this.action_param, "true_deleted");
				this.$gantt[this._methods[3]](sid);
				delete this._in_progress[marker];
				return this.callEvent("onAfterUpdate", [sid, action, tid, btag]);
		}

		if (this._in_progress[marker] !== "wait") {
			if (correct) {
				this.$gantt.setUserData(sid, this.action_param, "");
			}
			delete this._in_progress[marker];
		} else {
			delete this._in_progress[marker];
			this.setUpdated(tid, true, this.$gantt.getUserData(sid, this.action_param));
		}

		this.callEvent("onAfterUpdate", [originalSid, action, tid, btag]);
	}

	/**
	 * @desc: response from server
	 * @param: xml - XMLLoader object with response XML
	 * @type: private
	 */
	afterUpdate(that: any, xml: any, id?:any) {
		let _xml;
		if (arguments.length === 3) {
			_xml = arguments[1];
		} else {
			// old dataprocessor
			_xml = arguments[4];
		}
		let mode = this.getGanttMode();
		const reqUrl = _xml.filePath || _xml.url;

		if (this._tMode !== "REST" && this._tMode !== "REST-JSON") {
			if (reqUrl.indexOf("gantt_mode=links") !== -1) {
				mode = "link";
			} else {
				mode = "task";
			}
		} else {
			if (reqUrl.indexOf("/link") > reqUrl.indexOf("/task")) {
				mode = "link";
			} else {
				mode = "task";
			}
		}
		this.setGanttMode(mode);

		const ajax = this.$gantt.ajax;
		// try to use json first
		if ((window as any).JSON) {
			let tag;

			try {
				tag = JSON.parse(xml.xmlDoc.responseText);
			} catch (e) {

				// empty response also can be processed by json handler
				if (!xml.xmlDoc.responseText.length) {
					tag = {};
				}
			}

			if (tag) {
				const action = tag.action || this.getState(id) || "updated";
				const sid = tag.sid || id[0];
				const tid = tag.tid || id[0];
				that.afterUpdateCallback(sid, tid, action, tag);
				that.finalizeUpdate();
				this.setGanttMode(mode);
				return;
			}
		}
		// xml response
		const top = ajax.xmltop("data", xml.xmlDoc); // fix incorrect content type in IE
		if (!top) {
			return this.cleanUpdate(id);
		}
		const atag = ajax.xpath("//data/action", top);
		if (!atag.length) {
			return this.cleanUpdate(id);
		}

		for (let i = 0; i < atag.length; i++) {
			const btag = atag[i];
			const action = btag.getAttribute("type");
			const sid = btag.getAttribute("sid");
			const tid = btag.getAttribute("tid");

			that.afterUpdateCallback(sid, tid, action, btag);
		}
		that.finalizeUpdate();
	}

	cleanUpdate(id: any[]) {
		if (id) {
			for (let i = 0; i < id.length; i++) {
				delete this._in_progress[id[i]];
			}
		}
	}

	finalizeUpdate() {
		if (this._waitMode) {
			this._waitMode--;
		}

		if ((this.$gantt.mytype === "tree" || this.$gantt._h2) && this.updatedRows.length) {
			this.sendData();
		}
		this.callEvent("onAfterUpdateFinish", []);
		if (!this.updatedRows.length) {
			this.callEvent("onFullSync", []);
		}
	}

	/**
	 * @desc: initializes data-processor
	 * @param: anObj - dhtmlxGrid object to attach this data-processor to
	 * @type: public
	 */
	init(anObj: any) {
		if (this._initialized) {
			return;
		}
		this.$gantt = anObj;
		if (this.$gantt._dp_init) {
			this.$gantt._dp_init(this);
		}

		this._setDefaultTransactionMode();

		this.styles = {
			updated:"gantt_updated",
			order:"gantt_updated",
			inserted:"gantt_inserted",
			deleted:"gantt_deleted",
			invalid:"gantt_invalid",
			error:"gantt_error",
			clear:""
		};

		this._methods=["_row_style","setCellTextStyle","_change_id","_delete_task"];
		extendGantt(this.$gantt, this);
		const dataProcessorEvents = new DataProcessorEvents(this.$gantt, this);
		dataProcessorEvents.attach();
		this.attachEvent("onDestroy", function() {
			delete this.setGanttMode;
			delete this._getRowData;

			delete this.$gantt._dp;
			delete this.$gantt._change_id;
			delete this.$gantt._row_style;
			delete this.$gantt._delete_task;
			delete this.$gantt._sendTaskOrder;
			delete this.$gantt;

			dataProcessorEvents.detach();
		});
		this.$gantt.callEvent("onDataProcessorReady", [this]);
		this._initialized = true;
	}

	_setDefaultTransactionMode() {
		if (this.serverProcessor) {
			this.setTransactionMode("POST", true);
			this.serverProcessor += (this.serverProcessor.indexOf("?") !== -1 ? "&" : "?") + "editing=true";
			this._serverProcessor = this.serverProcessor;
		}
	}

	setOnAfterUpdate(handler) {
		this.attachEvent("onAfterUpdate", handler);
	}

	enableDebug(mode) {} // tslint:disable-line

	setOnBeforeUpdateHandler(handler) {
		this.attachEvent("onBeforeDataSending", handler);
	}

	/* starts autoupdate mode
		@param interval time interval for sending update requests
	*/
	setAutoUpdate(interval, user) {
		interval = interval || 2000;

		this._user = user || (new Date()).valueOf();
		this._needUpdate = false;
		// this._loader = null;
		this._updateBusy = false;

		this.attachEvent("onAfterUpdate", this.afterAutoUpdate); // arguments sid, action, tid, xml_node;

		this.attachEvent("onFullSync", this.fullSync);

		window.setInterval(() => {
			this.loadUpdate();
		}, interval);
	}

	/* process updating request answer
		if status == collision version is depricated
		set flag for autoupdating immidiatly
	*/
	afterAutoUpdate(sid, action, tid, xml_node) { // tslint:disable-line
		if (action === "collision") {
			this._needUpdate = true;
			return false;
		} else {
			return true;
		}
	}

	/* callback function for onFillSync event
		call update function if it's need
	*/
	fullSync() {
		if (this._needUpdate) {
			this._needUpdate = false;
			this.loadUpdate();
		}
		return true;
	}

	/* sends query to the server and call callback function
	*/
	getUpdates(url, callback) {
		const ajax = this.$gantt.ajax;
		if (this._updateBusy) {
			return false;
		} else {
			this._updateBusy = true;
		}

		// this._loader = this._loader || new dtmlXMLLoaderObject(true);

		// this._loader.async=true;
		// this._loader.waitCall=callback;
		// this._loader.loadXML(url);
		ajax.get(url, callback);

	}

	// I didn't found some use of _v and _a functions

	/* returns xml node value
		@param node
			xml node
	*/
	_v(node) {
		if (node.firstChild) {
			return node.firstChild.nodeValue;
		}
		return "";
	}


	/* returns values array of xml nodes array
		@param arr
			array of xml nodes
	*/
	_a(arr) {
		const res = [];
		for (let i = 0; i < arr.length; i++) {
			res[i] = this._v(arr[i]);
		}
		return res;
	}

	/* loads updates and processes them
	*/
	loadUpdate() {
		const ajax = this.$gantt.ajax;
		const version = this.$gantt.getUserData(0, "version");
		let url = this.serverProcessor + ajax.urlSeparator(this.serverProcessor) + ["dhx_user=" + this._user, "dhx_version=" + version].join("&");
		url = url.replace("editing=true&", "");
		this.getUpdates(url, (xml) => {
			const vers = ajax.xpath("//userdata", xml);
			this.obj.setUserData(0, "version", this._v(vers[0]));

			const upds = ajax.xpath("//update", xml);
			if (upds.length) {
				this._silent_mode = true;

				for (let i = 0; i < upds.length; i++) {
					const status = upds[i].getAttribute("status");
					const id = upds[i].getAttribute("id");
					const parent = upds[i].getAttribute("parent");
					switch (status) {
						case "inserted":
							this.callEvent("insertCallback", [upds[i], id, parent]);
							break;
						case "updated":
							this.callEvent("updateCallback", [upds[i], id, parent]);
							break;
						case "deleted":
							this.callEvent("deleteCallback", [upds[i], id, parent]);
							break;
					}
				}

				this._silent_mode = false;
			}

			this._updateBusy = false;
		});
	}

	destructor() {
		this.callEvent("onDestroy", []);
		this.detachAllEvents();

		this.updatedRows = [];
		this._in_progress = {}; // ?
		this._invalid = {};
		this._headers = null;
		this._payload = null;
		delete this._initialized;
	}

	setGanttMode(mode) {
		if (mode === "tasks") {
			mode = "task";
		} else if (mode === "links") {
			mode = "link";
		}

		const modes = this.modes || {};
		const ganttMode = this.getGanttMode();
		if (ganttMode) {
			modes[ganttMode] = {
				_in_progress : this._in_progress,
				_invalid: this._invalid,
				updatedRows : this.updatedRows
			};
		}

		let newState = modes[mode];
		if (!newState) {
			newState = modes[mode] = {
				_in_progress : {},
				_invalid : {},
				updatedRows : []
			};
		}
		this._in_progress = newState._in_progress;
		this._invalid = newState._invalid;
		this.updatedRows = newState.updatedRows;
		this.modes = modes;
		this._ganttMode = mode;
	}
	getGanttMode():string {
		return this._ganttMode;
	}

	_getRowData(id) {
		let task;
		if (this.getGanttMode() === "task") {
			task = this.$gantt.isTaskExists(id) ? this.$gantt.getTask(id) : { id };
		} else {
			task = this.$gantt.isLinkExists(id) ? this.$gantt.getLink(id) : { id };
		}

		task = this.$gantt.copy(task);

		const data = {};
		for (const key in task) {
			if (key.substr(0, 1) === "$") {
				continue;
			}

			const value = task[key];
			if (helpers.isDate(value)) {
				data[key] = this.$gantt.templates.xml_format !== this.$gantt.templates.format_date ? this.$gantt.templates.xml_format(value) : this.$gantt.templates.format_date(value);
			} else if(value === null) {
				data[key] = "";
			} else {
				data[key] = value;
			}
		}

		const taskTiming = this.$gantt._get_task_timing_mode(task);
		if(taskTiming.$no_start){
			task.start_date = "";
			task.duration = "";
		}
		if(taskTiming.$no_end){
			task.end_date = "";
			task.duration = "";
		}
		data[this.action_param] = this.$gantt.getUserData(id, this.action_param);
		return data;
	}

	_isFetchResult(result) {
		return result.body instanceof ReadableStream;
	}

	setSerializeAsJSON(flag: true) {
		this._serializeAsJson = flag;
	}
}